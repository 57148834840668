@font-face {
    font-family: 'SatoshiMedium';
    src: local('SatoshiMedium'), url(../fonts/Satoshi-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SwitzerSemibold';
    src: local('SwitzerSemibold'), url(../fonts/Switzer-Semibold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SatoshiRegular';
    src: local('SatoshiRegular'), url(../fonts/Satoshi-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dmaindashboard {
    width: 100%;
    height: 100vh;
    background-color: #161616;
}

.ddashboard {
    width: 100%;
    height: 100vh;
    background-color: #161616;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 1fr 7fr;
    width: 100%;
    height: 100vh;
}

.dmobilework {
    display: none;
}

.ddashboard .dsidebar {
    width: 18rem;
    height: 97vh;
    background-color: #282828;
    margin: 10px;
    padding-top: 1rem;
    border-radius: 0.6vw;
    display: flex;
    flex-direction: column;

    grid-row: 1 / span 2;
    grid-column: 1;
}

.duserinfo {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    /* border-bottom: 1px solid #222222; */
}

.davatar {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    margin-left: 1.3rem;
    background-color: #7e22ce;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.dusername {
    font-family: 'SatoshiMedium';
    color: #fff;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.dmenu {
    list-style: none;
    padding: 0rem 0;
}

.dmenuitem {
    display: flex;
    align-items: center;
    padding: 1.7rem 1.7rem;
    padding-bottom: 1.3rem;
    font-size: 1.1rem;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 0.1rem;
    border-top: 1px solid #222222;
}

.dmenuitem:hover {
    background-color: #333333;
}

.dmenuitem.active {
    background-color: #161616;
    color: #FFFFFF;
}



.content {
    padding: 20px;
}

.dmenuitem:hover {
    background-color: #161616;
}

.dmenu .dmenuitem img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
}

.dmenu .dmenuitem:nth-child(2) img {
    margin-right: 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
}

.bottommenu {
    list-style: none;
    padding: 0rem 0;
    margin-top: auto;
}

.bottommenu .dmenuitem {
    display: flex;
    align-items: center;
    padding: 1.7rem 1.7rem;
    padding-bottom: 1.3rem;
    font-size: 1.1rem;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 0.3s;
    gap: 0.1rem;
    border-top: 1px solid #222222;
}




.bottommenu .dmenuitem img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
}

.bottommenu .dmenuitem:nth-child(1) img {
    /* Target first item in bottom menu */
    margin-right: 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
}

.bottommenu .dmenuitem:nth-child(2) img {
    /* Target first item in bottom menu */
    margin-right: 0.9rem;
    width: 1.5rem;
    height: 1.5rem;
}

.ddashboard .dquotebar {
    width: calc(100% - 0.5rem);
    /* Adjusting width considering sidebar width and margins */
    height: 10vh;
    background-color: #282828;
    margin: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 0.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 0px;
    grid-row: 1;
    grid-column: 2;
}

.dquotebar .quotecontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dquotebar .quote {
    color: #fff;
    font-size: 1.2rem;
    margin: 0 auto;
    align-self: center;
    /* Center the quote vertically */
}

.dquotebar .newtaskbutton {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 12px 21px;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
}

.dquotebar .newtaskbutton:hover {
    background-color: #8205F4;
}


/* Main Screen */
.ddashboard .dmainscreen {
    width: calc(100% - 0.5rem);
    /* Adjusting width considering sidebar width and margins */
    height: 85.6vh;
    background-color: #282828;
    border-radius: 0.6vw;
    grid-row: 2;
    grid-column: 2;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.dstepline {
    background-color: #363232;
    width: 92%;
    height: 0.1rem;
    margin-top: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
}

.dstepcircle {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: #363232;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    color: #fff;
    padding: 21px 21px;
}

.dstepcircle.active {
    background-color: #8205F4;
}


.dstepline2 {
    background-color: #282828;
    width: 57rem;
    height: 0.1rem;
    margin-top: 2.7rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.dformcontainer {
    width: 100%;
    /* border: 1px solid rgb(200, 220, 20); */
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.dformcontainer>h3:first-child {
    margin-left: 11rem;
}

.dformcontainer>h3:nth-child(2) {
    margin-right: 24.3rem;
}

.dformcontainer2 {
    width: 100%;
    /* border: 1px solid rgb(200, 220, 20); */
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.dformcontainer2>input:nth-child(1) {
    background-color: #353535;
    width: 20rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.6rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;


    margin-left: 10.8rem;
}




.dformcontainer2>input:nth-child(2) {
    background-color: #353535;
    width: 20rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.6rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;

    margin-right: 16rem;
}

.dformdescription {
    align-self: flex-start;
    margin-top: 2rem;
    margin-left: 11rem;
}

.dformtextarea {
    background-color: #353535;
    width: 51rem;
    height: 10rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.6rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;
    align-self: flex-start;
    margin-left: 10.8rem;
    margin-top: 0.8rem;
    resize: none;
}

.dformrequiredlinks {
    align-self: flex-start;
    margin-left: 11rem;
    margin-top: 2rem;
}

.dforminputlink {
    background-color: #353535;
    width: 51rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.6rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;
    align-self: flex-start;
    margin-left: 10.8rem;
    margin-top: 0.8rem;
}

.dformbuttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    /* margin-top: 0.6rem; */
    gap: 3rem;
}

.dformbuttons>img {
    width: 2rem;
    height: 2rem;
    margin-top: 1.5rem;
    margin-right: 0.9rem;
    cursor: pointer;
    transition: transform 0.3s;
}

.dformbuttons>img:hover {
    transform: scale(1.1);
}

.dbutton-back {
    background-color: #353535;
    color: #fff;
}

.dbutton-next {
    background-color: #8205F4;
    color: #fff;
}

.button-active {
    background-color: #8205F4;
}

.dstep2form {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dstep2form .duploadimg {
    width: 15rem;
    height: 15rem;
}

/* file upload container */
.table-container {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.file-table {
    width: 80%;
    border-collapse: collapse;
    table-layout: fixed;
}

.file-table th,
.file-table td {
    border: 1px solid #3C3939;
    padding: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
}

.file-table th,
.file-table td {
    text-align: left;
    vertical-align: middle;
}


/* Set specific widths for each column */
.file-table th:nth-child(1),
.file-table td:nth-child(1) {
    width: 70%;
}

.file-table th:nth-child(2),
.file-table td:nth-child(2) {
    width: 15%;
}

.file-table th:nth-child(3),
.file-table td:nth-child(3) {
    width: 15%;
}

/* File upload end */

.duploadimg {
    width: 100px;
    height: 100px;
}

.dfileh4 {
    text-align: center;
    margin: 10px 0;
}

.dbrowsefile {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: center;
}

.dbrowsefile:hover {
    background-color: #45a049;
}


/* file upload container End */

.dstep2form h4 {
    font-family: 'SatoshiRegular';
    color: #7B7A7A;
    font-size: 1.3rem;
}

.dstep2form button {
    background-color: #353535;
    color: #fff;
    border: none;
    padding: 12px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 0.51rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dstep2form button:hover {
    background-color: #363636;
}

.dstep2form>.dformbuttons>img:nth-child(1) {
    width: 2rem;
    height: 2rem;
    margin-top: 5.5rem;
    margin-right: 0.9rem;
    cursor: pointer;
    transition: transform 0.3s;
}

.dstep2form>.dformbuttons>img:nth-child(2) {
    width: 2rem;
    height: 2rem;
    margin-top: 5.5rem;
    margin-right: 0.2rem;
    cursor: pointer;
    transition: transform 0.3s;
}

.dstep3form {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dstep3form1 {
    width: 62%;
    /* border: 1px solid royalblue; */
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.dstep3form2 {
    width: 64.5%;
    /* border: 1px solid royalblue; */
    margin-top: 0.8rem;
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-between;
}

.dstep3form2>input:nth-child(1) {
    background-color: #353535;
    width: 20rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.3rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;

    margin-left: 0.8rem;
}

.dstep3form2>input:nth-child(2) {
    align-self: flex-end;
    justify-self: flex-end;
    background-color: #353535;
    width: 10rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.3rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;

    margin-left: 7rem;
}

.dstep3form3 {
    width: 62%;
    /* border: 1px solid royalblue; */
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.dstep3form3>h3:nth-child(3) {
    margin-right: 3.6rem;
}

.dstep3form4 {
    width: 70%;
    /* border: 1px solid royalblue; */
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;
}

.dstep3form4>input:nth-child(1) {
    background-color: #353535;
    width: 17rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.3rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;
    margin-left: 3rem;
}

.dstep3form4>input:nth-child(2) {
    align-self: flex-end;
    justify-self: flex-end;
    background-color: #353535;
    width: 10rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.3rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;

    margin-right: 4rem;
}

.dstep3form4>input:nth-child(3) {
    align-self: flex-end;
    justify-self: flex-end;
    background-color: #353535;
    width: 10rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.3rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;

    margin-right: 2rem;
}

.dpaybutton {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 3rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.dpaybutton:hover {
    background-color: #8009f0e8;
}

.dstep3form>.dformbuttons>img:nth-child(1) {
    width: 2rem;
    height: 2rem;
    margin-top: 5.5rem;
    margin-right: 0.9rem;
    cursor: pointer;
    transition: transform 0.3s;
}


.dstep3form>.dformbuttons>img:nth-child(2) {
    width: 2rem;
    height: 2rem;
    margin-top: 5.5rem;
    margin-right: 0.9rem;
    cursor: pointer;
    transition: transform 0.3s;
}

.dstep4form {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dstep4form img {
    width: 24rem;
    height: 15rem;
}

.dstep4form h1 {
    font-family: 'SatoshiRegular';
}

.dstep4form h2 {
    font-weight: 300;
}

.dstep4form .dhomebutton {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 3rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.dstep4form .dsubmithome {
    background-color: #353535;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

/* Notification Dashboard */
.dnotificationmenu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
}

.dnotificationmenu img {
    width: 13rem;
    height: 13rem;
}

.dnotificationmenu h1 {
    font-family: 'SatoshiMedium';
}

.dnotificationmenu h2 {
    font-weight: 300;
}

.dnotificationmenu .dgoback {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}


/* Settings Dashboard */
.dsettingsmenu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    flex-direction: column;
}

.dsettingsmenu .dwelcomename {
    font-family: 'SatoshiMedium';
    font-size: 2rem;
    color: #fff;
    margin-top: 1.5rem;
    margin-left: 8.1rem;
    font-weight: 100;
}

.dsettingsmenu .dmemberactive {
    /* font-family: 'SatoshiRegular'; */
    font-size: 1.5rem;
    color: #fff;
    margin-left: 8.4rem;
    font-weight: 300;
}

.dsettingsmenu .dunderstepline {
    width: 100%;
    height: 0.1rem;
    margin-top: 3rem;
    background-color: #373737;
}

.demailinfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 15vh;
}

.demailinfo .duseremail {
    margin-left: 8.4rem;
    font-weight: 300;
}

.demailinfo .demail {
    margin-left: 7.5rem;
    font-weight: 300;
    text-decoration: underline;
}



.dusernameedit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 15vh;
}

.dusernameedit .dusercontact {
    margin-left: 8.4rem;
    font-weight: 300;
}

.dusernameedit .dfullname {
    margin-left: 6rem;
    font-weight: 300;
}

.dusernameedit input {
    background-color: #353535;
    width: 15rem;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0.6rem;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    color: white;
    margin-left: 21.5rem;
    margin-top: 6rem;
    position: absolute;
}

.dchangenamebutton {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 8.5rem;
    margin-left: 35.5rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.dchangenamebutton:hover {
    background-color: #8009f0e8;
}

.dlougoutmenu {
    width: 30rem;
    height: 16vh;
    margin-left: 35rem;
    margin-top: 20rem;
    position: absolute;
    flex-direction: column;
    border-radius: 0.5vw;
    background-color: #161616;
    visibility: hidden;
}

.dlougoutmenu h1 {
    font-family: 'SatoshiRegular';
    font-size: 1.8rem;
    color: #fff;
    margin-top: 1rem;
    margin-left: 1rem;
    font-weight: 100;
}

.dlougoutmenu .dlogoutbutton {
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 9px 16px;
    border-radius: 10px;
    font-size: 1rem;
    margin-left: 18.5rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.dlougoutmenu .dcancelbutton {
    background-color: #353535;
    color: #fff;
    border: none;
    padding: 9px 19px;
    border-radius: 10px;
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

.dastitle3chara {
    color: red;
    position: absolute;
    font-size: 1rem;
    opacity: 70%;
    margin-left: 31.4rem;
    margin-top: 19.5em;
    visibility: hidden;
}

.ddes10word {
    color: red;
    position: absolute;
    font-size: 1rem;
    opacity: 70%;
    margin-left: 31.4rem;
    margin-top: 33.5rem;
    visibility: hidden;
}

.dmytasks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
}

.dmytasks .dnodocuments {
    width: 12rem;
    height: 12rem;
}

.dmytasks h1 {
    font-family: 'SatoshiMedium';
    margin-top: 2rem;
    font-size: 2.4rem;
    font-weight: 500;
}

.dmytasks h3 {
    font-family: 'SatoshiRegular';
    font-size: 1.7rem;
    font-weight: 100;
}

.dmytasks .dmytaskdashboard {
    /* design this button  */
    background-color: #8205F4;
    color: #fff;
    border: none;
    padding: 11px 21px;
    border-radius: 10px;
    font-size: 1rem;
    margin-top: 3rem;
    cursor: pointer;
    transition: ease-in 0.3s;
}

/* create button click effect  */

.dmytasks .dmytaskdashboard:hover {
    background-color: #8009f0e8;
}

/* starting design table  */

.table-container2 {
    width: 76%;
    overflow-x: auto;
    /* background-color: #b82626; */
    border: 1px solid #3C3939;
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
}

.assignment-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    color: #fff;
}

.assignment-table th,
.assignment-table td {
    padding: 15px 27px;
}

.assignment-table th {
    /* background-color: #333; */
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #3C3939;
    border-width: 100%;
}

.assignment-table td {
    /* border-bottom: 1px solid #3C3939; */
}

.assignment-table tr:hover {
    background-color: #3b3b3b;
}

.assignment-table .status-in-progress {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
    width: 100px;
}

.assignment-table .status-download {
    background-color: #a832a6;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
    width: 100px;
}

.dinprogress {
    background-color: #353535;
    padding: 11px 25px;
    border-radius: 15px;
    border: none;
    outline: none;
    appearance: none;
    color: white;
}

.assignment-table th:nth-child(3) {
    text-align: center;
}

.assignment-table td:nth-child(3) {
    text-align: center;
}

.assignment-table td:nth-child(4) {
    text-align: center;
}

.assignment-table th:nth-child(4) {
    text-align: center;
}



/* starting design table end  */

/* For Small Devices */
@media (min-width: 319px) and (max-width: 380px) {
    .ddashboard {
        display: none;
        overflow: hidden;
    }

    .dmobilework {
        width: 100%;
        height: 100vh;
        background-color: #161616;
        display: flex;
        flex-direction: column;
        font-family: 'Arial', sans-serif;
        overflow: hidden;
    }

    .dmobilework .dmheader {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
    }

    .dmobilework .dmheader img {
        width: 1.3rem;
        height: 1.3rem;
    }

    .dmobilework .dmheader p:nth-child(2) {
        margin-left: 1rem;
        color: #ffffff;
        white-space: nowrap;
        font-size: 0.9rem;
    }

    .dmobilework .dmheader p:nth-child(3) {
        margin-left: 50%;
        font-size: 0.9rem;
    }

    .dmfourdots {
        width: 100%;
        height: 1vh;
        background-color: #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .dmfourdots .dmmydot {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: #E8DECF;
    }

    .dmfourdots .dmmydot.active {
        background-color: #8205F4;
    }

    .dminputdataform {
        width: 100%;
        height: 74vh;
        display: flex;
        flex-direction: column;
    }

    .dminputdataform:nth-child(3) {
        margin-top: 1.3rem;
        margin-left: 2.3rem;
        font-size: 0.9rem;
    }

    .dminputdataform .dminput1 {
        margin-top: 0.5rem;
        width: 80%;
        height: 6.5vh;
        padding: 15px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        color: white;
    }

    .dminputdataform .dmlabel2 {
        margin-top: 1.5rem;
        font-size: 0.9rem;
    }

    .dminputdataform .dminput2 {
        margin-top: 0.8rem;
        width: 80%;
        height: 16vh;
        padding: 15px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        color: white;
        overflow: hidden;
        resize: none;
    }

    .dminputdataform .dmlabel3 {
        margin-top: 1.5rem;
        font-size: 0.9rem;
    }

    .dminputdataform .dminput3 {
        margin-top: 0.7rem;
        width: 80%;
        height: 6.5vh;
        padding: 15px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        color: white;
    }

    .dminputdataform .dmlabel4 {
        margin-top: 1.5rem;
        font-size: 0.9rem;
    }

    .dminputdataform .dminput4 {
        margin-top: 0.7rem;
        width: 80%;
        height: 6.5vh;
        padding: 15px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        color: white;
    }

    .dmtwobuttons {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;
    }

    .dmtwobuttons .dmpreviousbutton {
        width: 30%;
        height: 6vh;
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 1px 1px;
        border-radius: 10px;
        font-size: 0.9rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmtwobuttons .dmnextbutton {
        width: 30%;
        height: 6vh;
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmbrowsecontainer {
        margin-top: 30%;
        width: 80%;
        height: 30vh;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        border: 3px dashed #3D3D3D;
        border-radius: 0.5rem;
    }

    .dmbrowsecontainer .dmdragtext {
        margin-top: 1rem;
        font-size: 1rem;
    }

    .dmbrowsecontainer .dmortext {
        font-size: 1rem;
    }

    .dmbrowselabel {
        background-color: #2E2E2E;
        padding: 15px 20px;
        border-radius: 2rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }

    .dmdisplayfiles {
        width: 80%;
        position: absolute;
        margin-top: 130%;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

    .dmcardinfo {
        display: flex;
        flex-direction: column;
    }

    .dmenterpaytext {
        margin-top: 1.3rem;
        font-family: 'SatoshiMedium';
        font-size: 1.5rem;
        color: #D4D4D4;
        text-align: center;
    }

    .dmcardnumbertext {
        font-size: 1rem;
        color: #D4D4D4;
        text-align: center;
        align-self: flex-start;
        margin-left: 2rem;
        margin-top: 3rem;
    }


    .dmcardcontainer {
        position: relative;
        display: inline-block;
    }

    .dmcardinputimage {
        width: 84%;
        /* Adjust as needed */
        cursor: text;
        /* Indicate that clicking will focus the input */
        margin-left: 1.9rem;
        margin-top: 0.8rem;
    }

    .dmcardnumber {
        position: absolute;
        top: 0.4rem;
        /* Align with the top margin of the image */
        left: 2.2rem;
        /* Align with the left margin of the image */
        width: 84%;
        height: 100%;
        opacity: 0;
        /* Initially hide the input */
        border: none;
        outline: none;
        /* Hide the default focus border */
        background: transparent;
        color: white;
        /* Adjust text color to contrast with the image */
        padding: 10px;
        /* Adjust padding to fit within the image design */
        font-size: 0.9rem;
    }

    .dmcardnumber:focus,
    .dmcardnumber:not(:placeholder-shown) {
        opacity: 1;
        /* Show input when focused or when there's text */
    }

    .dmnameanddate {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top: 1rem;
        padding: 0 1rem;
    }

    .dmnameontext {
        font-size: 1rem;
        color: #D4D4D4;
        margin-left: 1rem;
        margin-top: 1rem;
        white-space: nowrap;
    }

    .dmexpirytext {
        font-size: 1rem;
        color: #D4D4D4;
        margin-left: 5rem;
        margin-top: 1rem;
        white-space: nowrap;
    }

    .dmcontainernamedate {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-top: 0.6rem;
    }

    .dmfullname {
        width: 39%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 1rem;
        color: white;
        padding-left: 10px;
    }

    .dmexpirydate {
        max-width: 41%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 2.5rem;
        padding: 10px;
        color: white;
    }

    .dmcvvandzipcode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-top: 1.5rem;
    }

    .dmcvvtext {
        font-size: 1rem;
        color: #D4D4D4;
        margin-left: 1rem;
    }

    .dmzipcodetext {
        font-size: 1rem;
        color: #D4D4D4;
        margin-left: 7.5rem;
        white-space: nowrap;
    }

    .dmcontainervvzipcode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-top: 0.6rem;
    }

    .dmcvv {
        max-width: 39%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 1rem;
        color: white;
        padding-left: 10px;
    }

    .dmzipcode {
        width: 41%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 2.5rem;
        padding: 10px;
        color: white;
    }

    .dmpaybutton {
        width: 80%;
        height: 6vh;
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        cursor: pointer;
        transition: ease-in 0.3s;
        border-radius: 2rem;
        margin-top: 8.8rem;
        font-weight: bold;
        margin-left: 2.5rem;
        font-size: 0.9rem;
    }

    .congratsdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        flex-direction: column;
    }

    .congratsdiv img {
        width: 11.5rem;
        height: 7rem;
    }

    .congratsdiv h3 {
        font-family: 'SatoshiMedium';
        font-size: 1.7rem;
        font-weight: 500;
        text-align: center;
    }

    .congratsdiv p {
        width: 90%;
        font-family: 'SatoshiRegular';
        font-size: 1.2rem;
        font-weight: 100;
        text-align: center;
        margin-top: 0.8rem;
    }

    .congratsdiv button {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        margin-top: 3rem;
        cursor: pointer;
        transition: ease-in 0.3s;
    }


    /* Menu Bar */
    .dmmenubar {
        width: 60%;
        height: 100vh;
        background-color: #1d1c1c;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
    }

    .dmnameandlogo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
    }

    .dmnameandlogo img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
    }

    .dmnameandlogo p {
        font-family: 'SatoshiMedium';
        color: #fff;
        font-size: 1.2rem;
        margin-left: 1.5rem;
    }

    .dmdashboard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 4rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #3C3939;
        cursor: pointer;
        gap: 7vw;
    }

    .dmdashboard img {
        width: 1rem;
        height: 1rem;
        margin-left: 1.2rem;
    }

    .dmdashboard p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.3rem;
        /* margin-right: 2.1rem; */
    }

    .dmmytasks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #3C3939;
        cursor: pointer;
        gap: 7vw;
    }

    .dmmytasks img {
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 1rem;
    }

    .dmmytasks p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.3rem;
        white-space: nowrap;
    }

    .dmnotifications {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #3C3939;
        cursor: pointer;
        gap: 7vw;
    }

    .dmnotifications img {
        width: 1rem;
        height: 1rem;
        margin-left: 1.2rem;
    }

    .dmnotifications p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.3rem;
    }

    .dmsettings {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #3C3939;
        cursor: pointer;
        gap: 7vw;
    }

    .dmsettings img {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 1rem;
    }

    .dmsettings p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.3rem;
    }

    .dmlogout {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #3C3939;
        cursor: pointer;
        gap: 7vw;
    }

    .dmlogout img {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 1rem;
    }

    .dmlogout p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.3rem;
    }

    .dmnodocuments {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        gap: 0.4rem;
    }

    .dmnodocuments img {
        width: 6rem;
        height: 6rem;
    }

    .dmnodocuments h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 1.7rem;
    }

    .dmnodocuments h3 {
        font-size: 1.3rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;

    }

    .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00A876;
        /* Green background */
        border: none;
        border-radius: 20px;
        /* Rounded corners */
        color: white;
        padding: 7px 15px;
        font-size: 15px;
        font-weight: 300;
        cursor: pointer;
        margin-top: 6vw;
        transition: background-color 0.3s ease;
    }

    .add-button:hover {
        background-color: #008A60;
        /* Darker green on hover */
    }

    .plus-icon {
        font-size: 25px;
        margin-right: 15px;
        /* Space between the plus icon and the text */
    }

    .dmnoticlass {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        gap: 1rem;
    }

    .dmnoticlass img {
        width: 13rem;
        height: 13rem;
    }

    .dmnoticlass h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 1.6rem;
        text-align: center;
    }

    .dmnoticlass h2 {
        font-size: 1.3rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
        text-align: center;
    }

    .dmnoticlass button {
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        margin-top: 1.2rem;
    }

    .assignments-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }

    .assignment-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding: 12px;
        color: #FFF;
    }

    .assignment-info {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .dmtimecover {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        opacity: 61%;
        border-radius: 1.5vw;
        padding: 15px;
    }

    .assignment-icon {
        width: 3vw;
        height: 4vw;
    }

    .assignment-info h3 {
        font-size: 4.5vw;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
    }

    .assignment-info p {
        margin: 0;
        font-size: 4vw;
        color: #AAA;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: normal;
        margin-top: 0.8vw;
    }

    .status-button {
        background-color: #2E2E2E;
        color: #FFF;
        border: none;
        border-radius: 20px;
        padding: 12px 20px;
        font-size: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }

    .status-button:hover {
        background-color: #555;
    }

    .dmsettingmenu {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        margin-top: 3rem;
    }

    .dmsettingmenu h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 9vw;
        margin-left: 7vw;
        color: #F0F0F0;
    }

    .dmsettingmenu h3 {
        font-size: 1.1rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
        text-align: center;
        margin-left: 8.1vw;
        margin-top: 1vw;
        color: #F0F0F0;
    }

    .dmpropic {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 9vw;
        flex-direction: column;

    }

    .dmpropic .dmavatar {
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background-color: rgb(135, 236, 2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8vw;
        color: #F0F0F0;
    }

    .dmpropic p {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 1.5vw;
    }

    .dmtextfullname {
        font-size: 1.2rem;
        color: #F0F0F0;
        margin-left: 8vw;
        margin-top: 8vw;
    }

    .dminputfullname {
        width: 80%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 8vw;
        color: white;
        padding-left: 10px;
        height: 12vw;
        margin-top: 3vw;
        font-size: 4vw;
    }

    .dmtextemail {
        font-size: 1.5rem;
        color: #F0F0F0;
        margin-left: 8vw;
        margin-top: 8vw;
    }

    .dmdisplayemail {
        margin-left: 8vw;
        margin-top: 1vw;
        color: #F0F0F0;
        font-size: 4vw;
    }

    .dmapplybutton {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        margin-top: 9rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        align-self: center;

    }

    /* card design small device  */
    .dmcardcontainer {
        width: 15rem;
        height: 2.7rem;
        font-size: 13rem;
        background-color: #161616;
    }

    .stripe-element{
        width: 100%;
        box-sizing: border-box;
        padding: 12px 15px;
        border: 1px solid #353535;
        border-radius: 4px;
        background-color: transparent;
        margin-bottom: 0px;
        transition: box-shadow 150ms ease;
        font-size: 16px;
    }

    
}

/* For Medium Devices */
@media (min-width: 381px) and (max-width: 800px) {

    .ddashboard {
        display: none;
        overflow: hidden;
    }

    .dmobilework {
        width: 100%;
        height: 100vh;
        background-color: #161616;
        display: flex;
        flex-direction: column;
        font-family: 'Arial', sans-serif;
        overflow: hidden;
    }

    .dmobilework .dmheader {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        white-space: nowrap;
    }

    .dmobilework .dmheader img {
        width: 2rem;
        height: 2rem;
    }

    .dmobilework .dmheader p:nth-child(2) {
        font-size: 1.2rem;
        margin-left: 1rem;
        color: #ffffff;
    }

    .dmobilework .dmheader p:nth-child(3) {
        margin-left: 12rem;
        font-size: 1.1rem;
    }

    .dmfourdots {
        width: 100%;
        height: 2vh;
        background-color: #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        transition: 0.3s;
    }

    .dmfourdots .dmmydot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #E8DECF;
        transition: 0.17s;
    }

    .dmfourdots .dmmydot.active {
        background-color: #8205F4;
    }

    .dminputdataform {
        width: 100%;
        height: 74vh;
        display: flex;
        flex-direction: column;
    }

    .dminputdataform:nth-child(3) {
        margin-top: 3rem;
        margin-left: 2.3rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput1 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dminputdataform .dmlabel2 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput2 {
        margin-top: 1rem;
        width: 80%;
        height: 16vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
        overflow: hidden;
        resize: none;
    }

    .dminputdataform .dmlabel3 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput3 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dminputdataform .dmlabel4 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput4 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dmtwobuttons {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 3rem;
    }

    .dmtwobuttons .dmpreviousbutton {
        width: 30%;
        height: 6vh;
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmtwobuttons .dmnextbutton {
        width: 30%;
        height: 6vh;
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmbrowsecontainer {
        margin-top: 30%;
        width: 80%;
        height: 30vh;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        border: 3px dashed #3D3D3D;
        border-radius: 0.5rem;
    }

    .dmbrowsecontainer .dmdragtext {
        font-size: 1.2rem;
    }

    .dmbrowsecontainer .dmortext {
        font-size: 1.2rem;
    }

    .dmbrowselabel {
        background-color: #2E2E2E;
        padding: 20px 28px;
        border-radius: 2rem;
    }

    .dmdisplayfiles {
        width: 80%;
        position: absolute;
        margin-top: 130%;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

    .dmcardinfo {
        display: flex;
        flex-direction: column;
    }

    .dmenterpaytext {
        margin-top: 3rem;
        font-family: 'SatoshiMedium';
        font-size: 1.8rem;
        color: #D4D4D4;
        text-align: center;
    }

    .dmcardnumbertext {
        margin-top: 1rem;
        font-size: 1.2rem;
        color: #D4D4D4;
        text-align: center;
        align-self: flex-start;
        margin-left: 2.5rem;
        margin-top: 6rem;
    }

    .dmcardcontainer {
        position: relative;
        display: inline-block;
    }

    .dmcardinputimage {
        width: 84%;
        /* Adjust as needed */
        cursor: text;
        /* Indicate that clicking will focus the input */
        margin-left: 2.3rem;
        margin-top: 1rem;
    }

    .dmcardnumber {
        position: absolute;
        top: 0.4rem;
        /* Align with the top margin of the image */
        left: 2.6rem;
        /* Align with the left margin of the image */
        width: 84%;
        height: 100%;
        opacity: 0;
        /* Initially hide the input */
        border: none;
        outline: none;
        /* Hide the default focus border */
        background: transparent;
        color: white;
        /* Adjust text color to contrast with the image */
        padding: 10px;
        /* Adjust padding to fit within the image design */
        font-size: 1rem;
    }

    .dmcardnumber:focus,
    .dmcardnumber:not(:placeholder-shown) {
        opacity: 1;
        /* Show input when focused or when there's text */
    }


    .dmnameanddate {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top: 2.5rem;
        padding: 0 1rem;
    }

    .dmnameontext {
        font-size: 1.2rem;
        color: #D4D4D4;
        margin-left: 1.5rem;
        white-space: nowrap;
    }

    .dmexpirytext {
        font-size: 1.2rem;
        color: #D4D4D4;
        margin-left: 7.5rem;
        white-space: nowrap;
    }

    .dmcontainernamedate {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-top: 0.6rem;
    }

    .dmfullname {
        width: 99%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 1.5rem;
        color: white;
        padding-left: 10px;
        font-size: 16px;
    }

    .dmexpirydate {
        width: 41%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 3.1rem;
        padding: 10px;
        color: white;
    }

    .dmcvvandzipcode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;
        margin-top: 3rem;
    }

    .dmcvvtext {
        font-size: 1.2rem;
        color: #D4D4D4;
        margin-left: 1.5rem;
    }

    .dmzipcodetext {
        font-size: 1.2rem;
        color: #D4D4D4;
        margin-left: 9.5rem;
    }

    .dmcontainervvzipcode {
        display: flex;
        align-items: center;
        justify-content: space-between; /* Distributes space evenly */
        width: 100%;
        padding: 0 1rem;
        margin-top: 0.6rem;
    }
    
    .dmcvv {
        max-width: 39%;
        flex-grow: 1; /* Ensures it takes equal space */
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        padding-left: 10px;
        color: white;
        margin-left: 1rem; /* No margin on the first input */
    }
    
    .dmzipcode {
        width: 41%;
        flex-grow: 1; /* Ensures it takes equal space */
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        padding-left: 10px;
        color: white;
        margin-left: 2rem; /* Adjust this to increase the left margin */
        font-size: 16px;
    }
    
    
    

    .dmpaybutton {
        width: 80%;
        height: 6vh;
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
        margin-top: 11rem;
        margin-left: 2.5rem;
        font-size: 1.05rem;
    }

    .congratsdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        flex-direction: column;
    }

    .congratsdiv img {
        width: 16rem;
        height: 10rem;
    }

    .congratsdiv h3 {
        font-family: 'SatoshiMedium';
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }

    .congratsdiv p {
        width: 90%;
        font-family: 'SatoshiRegular';
        font-size: 1.2rem;
        font-weight: 100;
        text-align: center;
        margin-top: 0.8rem;
    }

    .congratsdiv button {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        margin-top: 3rem;
        cursor: pointer;
        transition: ease-in 0.3s;
    }


    /* Menu Bar */
    .dmmenubar {
        width: 60%;
        height: 100vh;
        background-color: #161616;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
    }

    .dmnameandlogo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
    }

    .dmnameandlogo img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    .dmnameandlogo p {
        font-family: 'SatoshiMedium';
        color: #fff;
        font-size: 1.6rem;
        margin-left: 1.5rem;
    }

    .dmdashboard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 4rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #222222;
        cursor: pointer;
        gap: 6vw;
    }

    .dmdashboard img {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 2rem;
    }

    .dmdashboard p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.6rem;
    }

    .dmmytasks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #222222;
        cursor: pointer;
        gap: 6vw;
    }

    .dmmytasks img {
        width: 1.7rem;
        height: 1.7rem;
        margin-left: 2rem;
    }

    .dmmytasks p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.6rem;
    }

    .dmnotifications {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #222222;
        cursor: pointer;
        gap: 6vw;
    }

    .dmnotifications img {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 2rem;
    }

    .dmnotifications p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.6rem;
    }

    .dmsettings {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #222222;
        cursor: pointer;
        gap: 6vw;
    }

    .dmsettings img {
        width: 1.7rem;
        height: 1.7rem;
        margin-left: 2rem;
    }

    .dmsettings p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.6rem;
    }

    .dmlogout {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #222222;
        cursor: pointer;
        gap: 6vw;
    }

    .dmlogout img {
        width: 1.9rem;
        height: 1.9rem;
        margin-left: 2rem;
    }

    .dmlogout p {
        /* font-family: 'SatoshiRegular'; */
        color: #fff;
        font-size: 1.6rem;
    }

    .dmnodocuments {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        gap: 1rem;
    }

    .dmnodocuments img {
        width: 11rem;
        height: 11rem;
    }

    .dmnodocuments h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 3rem;
    }

    .dmnodocuments h3 {
        font-size: 1.5rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;

    }

    .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00A876;
        /* Green background */
        border: none;
        border-radius: 20px;
        /* Rounded corners */
        color: white;
        padding: 14px 30px;
        font-size: 20px;
        font-weight: 300;
        cursor: pointer;
        margin-top: 6vw;
        transition: background-color 0.3s ease;
    }

    .add-button:hover {
        background-color: #008A60;
        /* Darker green on hover */
    }

    .plus-icon {
        font-size: 25px;
        margin-right: 15px;
        /* Space between the plus icon and the text */
    }

    .assignments-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }

    .assignment-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        padding: 12px;
        color: #FFF;
    }

    .assignment-info {
        display: flex;
        align-items: center;
        gap: 17px;
    }

    .dmtimecover {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        opacity: 61%;
        border-radius: 1.5vw;
        padding: 15px;
    }

    .assignment-icon {
        width: 5vw;
        height: 6vw;
    }

    .assignment-info h3 {
        font-size: 4.5vw;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: bold;
    }

    .assignment-info p {
        margin: 0;
        font-size: 4vw;
        color: #AAA;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: normal;
        margin-top: 0.8vw;
    }

    .status-button {
        background-color: #2E2E2E;
        color: #FFF;
        border: none;
        border-radius: 20px;
        padding: 16px 26px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 500;
    }

    .status-button:hover {
        background-color: #555;
    }

    .dmnoticlass {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        gap: 1rem;
    }

    .dmnoticlass img {
        width: 13rem;
        height: 13rem;
    }

    .dmnoticlass h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 1.6rem;
        text-align: center;
    }

    .dmnoticlass h2 {
        font-size: 1.3rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
        text-align: center;
    }

    .dmnoticlass button {
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        margin-top: 1.2rem;
    }

    .dmsettingmenu {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        margin-top: 3rem;
    }

    .dmsettingmenu h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 9vw;
        margin-left: 7vw;
        color: #F0F0F0;
    }

    .dmsettingmenu h3 {
        font-size: 1.1rem;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
        text-align: center;
        margin-left: 8.1vw;
        margin-top: 1vw;
        color: #F0F0F0;
    }

    .dmpropic {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 9vw;
        flex-direction: column;

    }

    .dmpropic .dmavatar {
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background-color: rgb(135, 236, 2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8vw;
        color: #F0F0F0;
    }

    .dmpropic p {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 1.5vw;
    }

    .dmtextfullname {
        font-size: 1.2rem;
        color: #F0F0F0;
        margin-left: 8vw;
        margin-top: 8vw;
    }

    .dminputfullname {
        width: 80%;
        appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        border: 1px solid #272727;
        border-radius: 0.3rem;
        height: 2.7rem;
        margin-left: 8vw;
        color: white;
        padding-left: 10px;
        height: 12vw;
        margin-top: 3vw;
        font-size: 4vw;
    }

    .dmtextemail {
        font-size: 1.5rem;
        color: #F0F0F0;
        margin-left: 8vw;
        margin-top: 8vw;
    }

    .dmdisplayemail {
        margin-left: 8vw;
        margin-top: 1vw;
        color: #F0F0F0;
        font-size: 4vw;
    }

    .dmapplybutton {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        margin-top: 9rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        align-self: center;

    }

    .dmlogoutmobilemenu {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: min-content;
        height: min-content;
        background-color: rgb(34, 33, 33);
        border-radius: 2%;
        padding: 10px 20px;
        top: 35%;
        left: 5%;
    }

    .dmlogoutmobilemenu h1 {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: bold;
        font-style: normal;
        font-size: 5vw;
        color: #F0F0F0;
        white-space: nowrap;
    }

    .dmyesnobuttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-top: 1rem;
    }

    .dmlogoutbutton {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
    }

    .dmcancelbutton {
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
    }

    /* card design */
    .dmcardcontainer {
        width: 20rem;
        height: 3rem;
        font-size: 13rem;
        background-color: #161616;
    }

    .stripe-element{
        width: 100%;
        box-sizing: border-box;
        padding: 12px 15px;
        border: 1px solid #353535;
        border-radius: 4px;
        background-color: transparent;
        margin-bottom: 0px;
        transition: box-shadow 150ms ease;
        font-size: 16px;
    }

}

/* For Tablet Devices */
@media (min-width: 767px) and (max-width: 1025px) {}


/* For Smaller Ipad Tablets */
@media (min-width: 500px) and (max-width: 700px) {}

/* Ipad Mini */
@media (min-width: 600px) and (max-width: 860px) {}

/* Iphone 14 Pro Max */
@media (min-width: 394px) and (max-width: 415px) {
    .ddashboard {
        display: none;
        overflow: hidden;
    }

    .dmobilework {
        width: 100%;
        height: 100vh;
        background-color: #161616;
        display: flex;
        flex-direction: column;
        font-family: 'Arial', sans-serif;
        overflow: hidden;
    }

    .dmobilework .dmheader {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
    }

    .dmobilework .dmheader img {
        width: 2rem;
        height: 2rem;
    }

    .dmobilework .dmheader p:nth-child(2) {
        font-size: 1.2rem;
        margin-left: 1rem;
        color: #ffffff;
    }

    .dmobilework .dmheader p:nth-child(3) {
        margin-left: 12rem;
        font-size: 1.1rem;
    }

    .dmfourdots {
        width: 100%;
        height: 2vh;
        background-color: #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .dmfourdots .dmmydot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #E8DECF;
    }

    .dmfourdots .dmmydot.active {
        background-color: #8205F4;
    }

    .dminputdataform {
        width: 100%;
        height: 74vh;
        display: flex;
        flex-direction: column;
    }

    .dminputdataform:nth-child(3) {
        margin-top: 3rem;
        margin-left: 2.3rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput1 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dminputdataform .dmlabel2 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput2 {
        margin-top: 1rem;
        width: 80%;
        height: 16vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
        overflow: hidden;
        resize: none;
    }

    .dminputdataform .dmlabel3 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput3 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dminputdataform .dmlabel4 {
        margin-top: 2.4rem;
        font-size: 1.2rem;
    }

    .dminputdataform .dminput4 {
        margin-top: 1rem;
        width: 80%;
        height: 6.5vh;
        padding: 20px;
        background-color: #161616;
        border: none;
        outline: none;
        appearance: none;
        border: 1px solid #272727;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: white;
    }

    .dmtwobuttons {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 3rem;
    }

    .dmtwobuttons .dmpreviousbutton {
        width: 30%;
        height: 6vh;
        background-color: #F5F0E5;
        color: #000000;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmtwobuttons .dmnextbutton {
        width: 30%;
        height: 6vh;
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 3px 3px;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        transition: ease-in 0.3s;
        font-weight: bold;
        border-radius: 2rem;
    }

    .dmbrowsecontainer {
        margin-top: 30%;
        width: 80%;
        height: 30vh;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        border: 3px dashed #3D3D3D;
        border-radius: 0.5rem;
    }

    .dmbrowsecontainer .dmdragtext {
        font-size: 1.2rem;
    }

    .dmbrowsecontainer .dmortext {
        font-size: 1.2rem;
    }

    .dmbrowselabel {
        background-color: #2E2E2E;
        padding: 20px 28px;
        border-radius: 2rem;
    }

    .dmdisplayfiles {
        width: 80%;
        position: absolute;
        margin-top: 130%;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

    .dmcardinfo {
        display: flex;
        flex-direction: column;
    }

    .dmenterpaytext {
        margin-top: 3rem;
        font-family: 'SatoshiMedium';
        font-size: 1.8rem;
        color: #D4D4D4;
        text-align: center;
    }

    .dmcardnumbertext {
        margin-top: 1rem;
        font-size: 1.2rem;
        color: #D4D4D4;
        text-align: center;
        align-self: flex-start;
        margin-left: 2.5rem;
        margin-top: 6rem;
    }

    .dmcardcontainer {
        position: relative;
        display: inline-block;
    }

    .dmcardinputimage {
        width: 84%;
        /* Adjust as needed */
        cursor: text;
        /* Indicate that clicking will focus the input */
        margin-left: 1.9rem;
        margin-top: 0.8rem;
    }

    .dmcardnumber {
        position: absolute;
        top: 0.4rem;
        /* Align with the top margin of the image */
        left: 2.2rem;
        /* Align with the left margin of the image */
        width: 84%;
        height: 100%;
        opacity: 0;
        /* Initially hide the input */
        border: none;
        outline: none;
        /* Hide the default focus border */
        background: transparent;
        color: white;
        /* Adjust text color to contrast with the image */
        padding: 10px;
        /* Adjust padding to fit within the image design */
        font-size: 0.9rem;
    }

    .dmcardnumber:focus,
    .dmcardnumber:not(:placeholder-shown) {
        opacity: 1;
        /* Show input when focused or when there's text */
    }

    .congratsdiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        flex-direction: column;
    }

    .congratsdiv img {
        width: 16rem;
        height: 10rem;
    }

    .congratsdiv h3 {
        font-family: 'SatoshiMedium';
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }

    .congratsdiv p {
        width: 90%;
        font-family: 'SatoshiRegular';
        font-size: 1.2rem;
        font-weight: 100;
        text-align: center;
        margin-top: 0.8rem;
    }

    .congratsdiv button {
        background-color: #009963;
        color: #fff;
        border: none;
        padding: 11px 21px;
        border-radius: 10px;
        font-size: 1rem;
        margin-top: 3rem;
        cursor: pointer;
        transition: ease-in 0.3s;
    }
}