
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.npbody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../images/bgimg.jpg');
    background-size: cover;
    background-position: center;
}

.npwrapper {
    width: 420px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}

.npwrapper h1 {
    font-size: 36px;
    text-align: center;
}

.npwrapper .npinput-box {
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.npinput-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.npinput-box input::placeholder {
    color: #fff;
}

.npinput-box:nth-of-type(1) i {
    position: absolute;
    right: 60px;
    top: 7.5rem;
    font-size: 20px;
    color: #ffffff;
}

.npinput-box:nth-of-type(2) i {
    position: absolute;
    right: 60px;
    top: 12.5rem;
    font-size: 20px;
    color: #ffffff;
}

.npwrapper button {
    width: 50%;
    height: 50px;
    background: #ffffff;
    border: none;
    outline: none;
    border-radius: 40px;
    font-size: 16px;
    color: #000000;
    font-family: 'SwitzerSemibold';
    cursor: pointer;
    margin: 20px 24%;
}

.npwrapper p {
    text-align: center;
    font-size: 16px; /* You can adjust the size if needed */
    color: #fff; /* Optional: set a color to match your design */
    margin-top: 10px; /* Optional: add some space above the message */
}
