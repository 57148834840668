@font-face {
    font-family: 'SatoshiMedium';
    src: local('SatoshiMedium'), url(../fonts/Satoshi-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SwitzerSemibold';
    src: local('SwitzerSemibold'), url(../fonts/Switzer-Semibold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SatoshiRegular';
    src: local('SatoshiRegular'), url(../fonts/Satoshi-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.fgbody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../images/bgimg.jpg');
    background-size: cover;
    background-position: center;
}

.fgwrapper {
    width: 420px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}

.fgwrapper h1 {
    font-size: 36px;
    text-align: center;
}

.fgwrapper .fginput-box {
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.fginput-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.fginput-box input::placeholder {
    color: #fff;
}

.fginput-box i:nth-child(2) {
    position: absolute;
    right: 60px;
    top: 7.5rem;
    font-size: 20px;
    color: #fff;
}

.fgwrapper button {
    width: 50%;
    height: 50px;
    border: none;
    outline: none;
    background: #ffffff;
    color: black;
    font-size: 18px;
    font-family: 'SwitzerSemibold';
    border-radius: 40px;
    cursor: pointer;
    margin: 20px 25%;
}

.fgwrapper button:hover {
    background: #f1f1f1;
}