* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.lbody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../images/Rectangle.png');
    background-size: cover;
    background-position: center;
}

.lwrapper {
    width: 420px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}

.lwrapper h1 {
    font-size: 36px;
    text-align: center;
}

.lwrapper .linput-box {
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.linput-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.linput-box input::placeholder {
    color: #fff;
}

.linput-box i:nth-child(2) {
    position: absolute;
    right: 60px;
    font-size: 20px;
    color: #fff;
    margin: 15px 0 0 15px;
    cursor: pointer;
}

.lwrapper .lremember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.lremember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
    cursor: pointer;
}

.lremember-forgot a {
    color: #fff;
    text-decoration: none;
}

.lremember-forgot a:hover {
    text-decoration: underline;
}

.lwrapper .lsubmitbtnaboutus {
    width: 100%;
    height: 45px;
    background-color: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.lwrapper .lregister-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.lregister-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}


.lregister-link p a:hover {
    text-decoration: underline;
}
