/* PaymentForm.css */

/* Base Styles */

.payment-form {
  max-width: 1000px;
  margin: 0 auto;
}

.payment-form-title {
  text-align: center;
  margin-bottom: 20px;
  color: whitesmoke;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Spacing above the form fields */
.form-fields-wrapper {
  margin-top: 50px; /* Adjust this value as needed */
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 72px;
}

.form-group {
  flex: 1;
}

.wide-width {
  flex: 2;
}

.narrow-width {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  color: whitesmoke;
}

.stripe-element,
.custom-input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 15px;
  border: 1px solid #353535;
  border-radius: 4px;
  background-color: #353535;
  margin-bottom: 0px;
  transition: box-shadow 150ms ease;
  font-size: 16px;
  color: #ffffff; /* Text color for desktop */
}

.stripe-element::placeholder,
.custom-input::placeholder {
  color: gray;
}

.stripe-element:focus,
.custom-input:focus {
  box-shadow: 0 0 0 2px #2d9cdb;
  outline: none;
}

.stripe-element--invalid {
  border-color: #fa755a;
}

.payment-error {
  color: #fa755a;
  margin-bottom: 20px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  height: 100px;      /* Optional: Set a height if needed */
}

/* Adjust the pay-button styles */
.pay-button {
  width: 25%; /* Adjust or remove for mobile */
  padding: 12px;
  background-color: #2d9cdb;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #9acfe8;
  cursor: not-allowed;
}

.pay-button:hover:not(:disabled) {
  background-color: #1c7bbf;
}

/* Mobile-Specific Styles */

/* Media Query for Small Devices */
@media (min-width: 319px) and (max-width: 380px) {
  .payment-form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0 15px; /* Add some padding on mobile */
  }

  .payment-form-title {
    font-size: 1.5rem;
    margin-top: 70px;
    text-align: center;
    color: #D4D4D4;
  }

  .form-fields-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 10rem;
  }

  .form-row {
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
  }

  .form-group {
    width: 100%;
    height: 10vh;
  }

  .stripe-element,
  .custom-input {
    width: 100%;
    background-color: transparent;
    color: white;
    font-size: 18px;
  }

  .stripe-element::placeholder,
  .custom-input::placeholder {
    color: gray;
  }

  label {
    font-size: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #D4D4D4;
  }

  .button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .pay-button {
    width: 100%;
    max-width: 400px;
  }

  /* First Row: Card Number */
  .form-row:nth-child(1) {
    display: flex;
    justify-content: center;
  }

  /* Second Row: Full Name */
  .form-row:nth-child(2) {
    display: flex;
    justify-content: center;
  }

  /* Third Row: CVC and Expiration Date */
  .form-row:nth-child(3) {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .form-row:nth-child(3) .form-group {
    width: 48%;
  }

  .payment-error {
    text-align: center;
    margin-bottom: 10px;
    color: #fa755a;
  }
}


/* Media Query for Medium Devices */
@media (min-width: 381px) and (max-width: 800px) {
  .payment-form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0 15px;
  }

  .payment-form-title {
    font-size: 1.8rem;
    margin-top: 70px;
    text-align: center;
    color: #D4D4D4; 
  }

  .form-fields-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 10rem;
    /* border: 1px solid royalblue; */
  }

  .form-row {
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
  }

  .form-group {
    width: 100%;
    height: 10vh;
  }


  .stripe-element,
  .custom-input {
    width: 100%;
    background-color: transparent;
    color: white;
    font-size: 18px;
  }

  .stripe-element::placeholder,
  .custom-input::placeholder {
    color: gray;
  }

  label {
    font-size: 19px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #D4D4D4;
  }

  .button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .pay-button {
    width: 100%;
    max-width: 400px;
  }

  /* First Row: Card Number */
  .form-row:nth-child(1) {
    display: flex;
    justify-content: center;
  }

  /* Second Row: Full Name */
  .form-row:nth-child(2) {
    display: flex;
    justify-content: center;
  }

  /* Third Row: CVC and Expiration Date */
  .form-row:nth-child(3) {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .form-row:nth-child(3) .form-group {
    width: 48%;
  }

  .payment-error {
    text-align: center;
    margin-bottom: 10px;
    color: #fa755a;
  }
}
