* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sbody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../images/Rectangle.png');
    background-size: cover;
    background-position: center;
}

.swrapper {
    width: 420px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}

.swrapper h1 {
    font-size: 36px;
    text-align: center;
}

.swrapper .sinput-box {
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.sinput-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.sinput-box input::placeholder {
    color: #fff;
}

.sinput-box i:nth-child(2) {
    position: absolute;
    right: 60px;
    font-size: 20px;
    color: #fff;
    margin: 15px 0 0 15px;
    cursor: pointer;
}

.swrapper .sremember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.sremember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
    cursor: pointer;
}

.sremember-forgot a {
    color: #fff;
    text-decoration: none;
}

.sremember-forgot a:hover {
    text-decoration: underline;
}

.swrapper .ssubmitbtnaboutus {
    width: 100%;
    height: 45px;
    background-color: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.swrapper .sregister-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.sregister-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}


.sregister-link p a:hover {
    text-decoration: underline;
}

.error-message {
    color: yellow;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 15px;
}

.message {
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}

.message.success {
    background-color: rgba(76, 175, 80, 0.2);
    /* Green background */
    color: #dbf111;
    /* Green text */
    border: 1px solid #dbf111;
}

.message.error {
    background-color: rgba(244, 67, 54, 0.2);
    /* Red background */
    color: #F44336;
    /* Red text */
    border: 1px solid #F44336;
}