@font-face {
    font-family: 'SatoshiMedium';
    src: local('SatoshiMedium'), url(../fonts/Satoshi-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SwitzerSemibold';
    src: local('SwitzerSemibold'), url(../fonts/Switzer-Semibold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SatoshiRegular';
    src: local('SatoshiRegular'), url(../fonts/Satoshi-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.aadminboard {
    width: 100%;
    height: 100vh;
}

.amyboard {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161616;
    flex-direction: column;
}

.amyboard h2 {
    font-family: 'SatoshiMedium';
    align-self: flex-start;
    margin-left: 30.5%;
    margin-top: 2%;
}

.asearchbar {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.asearchbar input {
   background-color: #292929;
   border: none;
   padding: 10px;
   font-family: 'SatoshiRegular';
   border-radius: 6px;
   width: 20%;
   font-size: 1rem;
   margin-left: 30.5%;
   color: white;
}

.asearchbar img {
    margin-right: 3rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

/* starting editing table  */
.atable3 {
    width: 60%;
    overflow-x: auto;
    /* background-color: #b82626; */
    border: 1px solid #3C3939;
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
}

.adisplaytable {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    color: #fff;
}

.adisplaytable th,
.adisplaytable td {
    padding: 15px 27px;
}

.adisplaytable th {
    /* background-color: #333; */
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #3C3939;
    border-width: 100%;
}

.adisplaytable td {
    /* border-bottom: 1px solid #3C3939; */
    width: 33%;
}

.adisplaytable tr:hover {
    background-color: #3b3b3b;
}

/* ending editing table  */

.asurecomplete {
    margin-top: 1rem;
}

.ayes {
    background-color: #28e928;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'SatoshiRegular';
    font-size: 1rem;
    margin-top: 1rem;
    transition: 0.3s;
}

.ayes:hover {
    background-color: #51ff51;
}

.ano {
    background-color: #ff0000;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'SatoshiRegular';
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
}

