/* Check CSS directory for the complete CSS file */


@font-face {
    font-family: 'SatoshiMedium';
    src: local('SatoshiMedium'), url(./fonts/Satoshi-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SwitzerSemibold';
    src: local('SwitzerSemibold'), url(./fonts/Switzer-Semibold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'SatoshiRegular';
    src: local('SatoshiRegular'), url(./fonts/Satoshi-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    color: white;
    font-family: "Inter", sans-serif;
}

::selection {
    background-color: black;
    color: white;
}

.link {
    text-decoration: none;
    color: rgb(255, 19, 19);
}

/* Navigation Bar Css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15vh;
    /* border: 1px solid red; */
    overflow: hidden;
}

.navbar img {
    width: 7rem;
    height: 5.5rem;
    margin-top: 0.6vw;
    margin-left: 2.1vw;
    overflow: hidden;
}

.rightnav {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3rem;
    overflow: hidden;
}

.rightnav h4 {
    font-weight: 500;
    font-size: 1.05rem;
    color: grey;
    margin-top: 0.5rem;
    cursor: pointer;
    overflow: hidden;
}

.arrow1 {
    border: solid grey;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3.4px;
    transform: rotate(45deg);
    margin-top: 0.19rem;
    margin-right: 16.5rem;
    position: absolute;
    overflow: hidden;
}

.arrow2 {
    border: solid grey;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3.4px;
    transform: rotate(45deg);
    margin-top: 0.23rem;
    margin-right: 10.48rem;
    position: absolute;
    overflow: hidden;
}

/* accountdashboard */
.accountdashboard {
    width: 10rem;
    height: 14rem;
    background-color: #000000;
    position: absolute;
    z-index: 1;
    right: 10vw;
    top: 9.1vh;
    box-sizing: border-box;
    width: 200px;
    /* height: min-content; */
    padding: 5px 5px 5px 5px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    align-content: center;
    border-radius: 10px;
    visibility: hidden;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "bggrid1"
        "bggrid2"
        "bggrid3"
        "bggrid4";
}

.bggrid1 {
    grid-area: bggrid1;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "icon1 paragraph1";
    grid-area: bggrid1;
}

.bggrid2 {
    grid-area: bggrid2;
    width: 100%;
    height: 100%;
    background-color: #000000;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "icon2 paragraph2";
    grid-area: bggrid2;
}

.bggrid3 {
    grid-area: bggrid3;
    width: 100%;
    height: 100%;
    background-color: #000000;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "icon3 paragraph3";
    grid-area: bggrid3;
}

.bggrid4 {
    grid-area: bggrid4;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "icon4 paragraph4";
    grid-area: bggrid4;
}

.accountdashboard svg:nth-child(1) {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.accountdashboard p:nth-child(2) {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.2rem;
    margin-left: 0rem;
    cursor: pointer;
}

.accountdashboard svg:nth-child(3) {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.accountdashboard p:nth-child(4) {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.25rem;
    margin-left: -1.8rem;
    cursor: pointer;
}

.accountdashboard svg:nth-child(5) {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.accountdashboard p:nth-child(6) {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.25rem;
    margin-left: -1.8rem;
    cursor: pointer;
}

.accountdashboard svg:nth-child(7) {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.accountdashboard p:nth-child(8) {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1rem;
    margin-left: -1.8rem;
    cursor: pointer;
}

/* Policy Dashboard */
.policydashboard {
    width: 10rem;
    height: 11rem;
    background-color: #000000;
    position: absolute;
    z-index: 1;
    right: 9vw;
    top: 9.1vh;
    box-sizing: border-box;
    width: 200px;
    /* height: min-content; */
    padding: 5px 5px 5px 5px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    align-content: center;
    border-radius: 10px;
    visibility: hidden;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "polic1"
        "polic2"
        "polic3";
}

.polic1 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "svg1 para1";
    grid-area: polic1;
}

.polic2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "svg2 para2";
    grid-area: polic2;
}

.polic3 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "svg3 para3";
    grid-area: polic3;
}

.mysvg1 {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
    grid-area: svg1;
}

.mypara1 {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.2rem;
    margin-left: 0rem;
    cursor: pointer;
    grid-area: para1;
}

.mysvg2 {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
    grid-area: svg2;
}

.mypara2 {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.25rem;
    margin-left: 0rem;
    cursor: pointer;
    grid-area: para2;
}

.mysvg3 {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 1.1rem;
    margin-left: 1.5rem;
    cursor: pointer;
    grid-area: svg3;
}

.mypara3 {
    color: rgb(255, 252, 252);
    font-size: 0.93rem;
    margin-top: 1.25rem;
    margin-left: 0rem;
    cursor: pointer;
    grid-area: para3;
}

/* Policy Dashboard */

.navbar button {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    outline: none;
    border: none;
    appearance: none;
    font-weight: 530;
    padding: 0.65rem;
    border-radius: 0.7rem;
    margin-right: 2.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
}

.navbar button:hover {
    opacity: 0.9;
    transition: 0.3s;
    cursor: pointer;
    overflow: hidden;
}

.mygrid {
    /* height: auto; */
    position: relative;
    bottom: 117vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.blackbox {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 15rem;
    height: 2.2rem;
    background-color: #0D0D0D;
    border-radius: 0.6rem;
    border: 1px solid rgb(82, 77, 77);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.blackbox p {
    color: #5F5F5F;
    font-size: 0.85rem;
}

.blackbox .readmore {
    cursor: pointer;
    margin-left: 0.4rem;
    color: #ffffff;
}

.blackbox svg {
    margin-left: 0.1rem;
    cursor: pointer;
    color: #ffffff;
    width: 0.95rem;
    height: 0.95rem;
}

.mygrid h1 {
    font-weight: 600;
    font-family: 'SwitzerSemibold';
    font-size: 7.55rem;
    margin-left: 1.5vw;
    /* white-space: nowrap; */
    /* font-family: "Helvetica"; */
    line-height: 7rem;
}

.mygrid .pathwayclass {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.mygrid .successclass {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.char {
    transform: translateY(195px);
    transition: transform .5s;
}


/* Background Gradient */
.testcontainer {
    width: 100%;
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #000000;
    position: relative;
    overflow: hidden;
}

.testrect {
    width: 100%;
    height: 90%;
    background: linear-gradient(180deg, #000000 0%, rgba(89, 38, 182, 1) 78.64935247747748%, rgb(230, 169, 253) 100%);
    overflow: hidden;
    position: absolute;
    border-radius: 0px 0px 0px 0px;
}

.circleoutline {
    width: 146%;
    height: 100%;
    display: block;
    background: radial-gradient(33.7% 50% at 50% 0%, #ffffff 0%, rgba(255, 255, 255, 0) 68.44911317567568%);
    overflow: hidden;
    position: absolute;
    border-radius: 100%;
    top: 67.9%;
}

.circlecss {
    width: 155%;
    height: 100%;
    display: block;
    box-shadow: 0px 0px 250px 0px rgba(230, 169, 251, 0.6);
    background: radial-gradient(64.4% 50% at 50% 50%, #000000 77.98599380630633%, rgb(160, 104, 252) 100%);
    overflow: hidden;
    position: absolute;
    border-radius: 100%;
    top: 68%;
}

.welcomegreat {
    font-weight: 300;
    /* font-family: 'Satoshiregular'; */
    margin-top: 2rem;
    font-size: 1.5rem;
    width: 40rem;
    text-align: center;
}

.loginbutton {
    background-color: white;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    outline: none;
    border: none;
    appearance: none;
    font-weight: 530;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 0.7rem;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 92vh;
    left: 45.8%;
}

.cursorimg {
    width: 211px;
    height: 211px;
    display: block;
    -webkit-filter: drop-shadow(10px -19px 10px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(10px -19px 10px rgba(0, 0, 0, 0.5));
    overflow: visible;
    transform: rotate(9deg);
    z-index: 1;
    aspect-ratio: 1 / 1;
    background-image: url("./images/cursor.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer;
    position: absolute;
    margin-right: 51.5rem;
    margin-top: 10vw;
}

.messageimg {
    width: 211px;
    height: 211px;
    display: block;
    -webkit-filter: drop-shadow(-10px 20px 10px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(-10px 20px 10px rgba(0, 0, 0, 0.5));
    overflow: visible;
    transform: rotate(-8deg);
    z-index: 1;
    aspect-ratio: 1 / 1;
    background-image: url("./images/messageimg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    margin-left: 56rem;
    margin-top: 8vw;
}

.logogrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 33rem;
    overflow: hidden;
}

.trusted {
    font-family: "SatoshiMedium";
    font-weight: medium;
    font-size: 1rem;
    margin-top: 0rem;
    color: #797979;
}

.logogrid .mylogos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
    overflow: hidden;
}

.logogrid .mylogos img {
    width: 10rem;
    height: 7rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
}

.mylogos img:nth-child(1) {
    width: 9rem;
    height: 7rem;
}

.mylogos img:nth-child(2) {
    width: 7rem;
    height: 3rem;
}

.mylogos img:nth-child(3) {
    width: 7rem;
    height: 3rem;
}

.mylogos img:nth-child(4) {
    width: 6rem;
    height: 4.8rem;
}

.mylogos img:nth-child(5) {
    width: 9rem;
    height: 8rem;
}

.mylogos img:nth-child(6) {
    width: 6rem;
    height: 4rem;
}

.threemains {
    margin-top: 5rem;
    justify-self: center;
    display: flex;
    gap: 2.5vw;
}

.seconddescription {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    /* Using auto instead of 1fr for dynamic row height */
    gap: 0px;
    position: relative;
    bottom: 25rem;
}

.seconddescription h1 {
    font-family: 'SwitzerSemibold';
    justify-self: center;
    font-size: 3.5rem;
    margin: 0;
    white-space: nowrap;
    /* Reset margin to remove default spacing */
}

.seconddescription p {
    font-family: 'SatoshiRegular';
    justify-self: center;
    font-size: 1.5rem;
    width: 33rem;
    text-align: center;
    margin: 0;
    /* Reset margin to remove default spacing */
    margin-top: 1.5rem;
}

.seconddescription .boxx1 {
    box-sizing: border-box;
    width: 25vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 40px 40px 40px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 30;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 auto;
    /* Center the box horizontally */
}

.seconddescription .boxx2 {
    box-sizing: border-box;
    width: 25vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 40px 40px 40px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 30;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 auto;
    /* Center the box horizontally */
}

.seconddescription .boxx3 {
    box-sizing: border-box;
    width: 25vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 40px 40px 40px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 30;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 auto;
    /* Center the box horizontally */
}



.seconddescription .boxx1 .icon1 {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    align-content: center;
    flex-wrap: nowrap;
    gap: 10;
    aspect-ratio: 1 / 1;
    position: absolute;
    border-radius: 10px;
    background-color: white;
}

.seconddescription .boxx1 h6 {
    font-family: 'SwitzerSemibold';
    font-size: 1.1rem;
    margin-top: 5rem;
}

.seconddescription .boxx1 p {
    margin: 1rem;
    font-weight: 200;
    font-family: 'SatoshiRegular';
    font-size: 1rem;
    width: 17rem;
    color: #C9C9C9;
}

.seconddescription .boxx2 .icon2 {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    align-content: center;
    flex-wrap: nowrap;
    gap: 10;
    aspect-ratio: 1 / 1;
    position: absolute;
    border-radius: 10px;
    background-color: white;
}

.seconddescription .boxx2 h6 {
    font-family: 'SwitzerSemibold';
    font-size: 1.1rem;
    margin-top: 5rem;
}

.seconddescription .boxx2 p {
    margin: 1rem;
    font-weight: 200;
    font-family: 'SatoshiRegular';
    font-size: 1rem;
    max-width: 19rem;
    color: #C9C9C9;
}

.seconddescription .boxx3 .icon3 {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    align-content: center;
    flex-wrap: nowrap;
    gap: 10;
    aspect-ratio: 1 / 1;
    position: absolute;
    border-radius: 10px;
    background-color: white;
}

.seconddescription .boxx3 h6 {
    font-family: 'SwitzerSemibold';
    font-size: 1.1rem;
    margin-top: 5rem;
}

.seconddescription .boxx3 p {
    margin: 1rem;
    font-weight: 200;
    font-family: 'SatoshiRegular';
    font-size: 1rem;
    max-width: 19rem;
    color: #C9C9C9;
}

.mysecondbackground {
    width: 100%;
    height: 200vh;
    position: absolute;
    top: 48%;
}

.secondbg {
    width: 100%;
    height: 100%;
    /* Changed height to auto for responsiveness */
    position: absolute;
}


.thirddescription {
    position: relative;
    bottom: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200%;
}

.thirddescription h1 {
    font-family: 'SwitzerSemibold';
    font-size: 3.5rem;
    margin: 0;
}

.thirddescription p {
    font-family: 'SatoshiRegular';
    font-size: 1.5rem;
    color: #c9c9c9;
    width: 35rem;
    text-align: center;
    margin-top: 1rem;
}

.thirddescription .appinterface {
    margin-top: 5vw;
    width: 65rem;
    height: 45rem;
}

.thirdreview {
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    bottom: 14rem;
    flex-direction: column;
    /* border: 1px solid red; */
}

.thirdreview h1 {
    font-family: 'SwitzerSemibold';
    font-size: 3.5rem;
    margin: 0;
    margin-top: 5rem;
    color: rgb(255, 255, 255);
    align-self: center;
    justify-self: center;
    margin-bottom: 3rem;
}

.thirdreview .userreview {
    height: 60rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

/* All Cards Begin Here */
/* Card 1 */
.thirdreview .userreview .card1 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-right: 50rem;
}

.thirdreview .userreview .card1 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card1 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://www.iamrenew.com/wp-content/uploads/2019/02/Stella-Bowles.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 6.5rem;
}

.thirdreview .userreview .card1 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 6.8rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card1 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 2.3rem;
}

/* Card 2 */

.thirdreview .userreview .card2 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
}

.thirdreview .userreview .card2 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card2 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://cdn2.psychologytoday.com/assets/styles/manual_crop_1_91_1_1528x800/public/field_blog_entry_teaser_image/2022-02/lau-keith-dczsxuaajxs-unsplash.jpg?itok=mXB2PJKd");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 6rem;
}

.thirdreview .userreview .card2 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 6rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card2 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 2.7rem;
}

/* Card 3 */
.thirdreview .userreview .card3 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-left: 50rem;
}

.thirdreview .userreview .card3 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card3 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWa7gSuCMcLmghjUWs4E1Dtsvq-T7UkfhPs9D_JWF5swTCTNBuaJLVvkEyTkM9qhUw5Cc&usqp=CAU");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 5rem;
}

.thirdreview .userreview .card3 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 5.1rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card3 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 3rem;
}

/* Card 4 */
.thirdreview .userreview .card4 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-right: 50rem;
    margin-top: 16rem;
}

.thirdreview .userreview .card4 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card4 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://media.istockphoto.com/id/1241553972/photo/an-asian-chinese-teenager-boy-looking-at-camera-crossed-his-arm-serious-face.jpg?s=612x612&w=0&k=20&c=tddVH7z2D4IQJBLGo1fqP4-zcNsZWUdXKoevM7CEebM=");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 6.5rem;
}

.thirdreview .userreview .card4 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 6.8rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card4 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 3.4rem;
}

/* Card 5 */
.thirdreview .userreview .card5 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 16rem;
}

.thirdreview .userreview .card5 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card5 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://c8.alamy.com/comp/KX5W0K/1-indian-college-girl-holding-book-showing-thumbsup-in-park-KX5W0K.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 7rem;
}

.thirdreview .userreview .card5 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 7.1rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card5 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 2.5rem;
}

/* Card 6 */
.thirdreview .userreview .card6 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 16rem;
    margin-left: 50rem;
}

.thirdreview .userreview .card6 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card6 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://image1.masterfile.com/getImage/NjI1LTAxNzQ3MTMyZW4uMDAwMDAwMDA=APmqxI/625-01747132en_Masterfile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 6.5rem;
}

.thirdreview .userreview .card6 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 6.5rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card6 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 3.1rem;
}

/* Card 7 */
.thirdreview .userreview .card7 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 33rem;
    margin-right: 50rem;
}

.thirdreview .userreview .card7 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card7 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://image1.masterfile.com/getImage/NjI1LTAxNzQ3MTMyZW4uMDAwMDAwMDA=APmqxI/625-01747132en_Masterfile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 7rem;
}

.thirdreview .userreview .card7 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 7rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card7 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 2.5rem;
}

/* Card 8 */
.thirdreview .userreview .card8 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 33rem;
}

.thirdreview .userreview .card8 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card8 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://c.superprof.com/i/a/20778073/10045715/600/20220526094421/maybe-you-want-meet-friendly-chinese-girl-chinese-college-student-majoring-english-can-help-you-with-your-chinese-all.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 5rem;
}

.thirdreview .userreview .card8 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card8 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 3rem;
}

/* Card 9 */
.thirdreview .userreview .card9 {
    box-sizing: border-box;
    width: 340px;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
    background-color: #0d0d0d;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    position: absolute;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 33rem;
    margin-left: 50rem;
}

.thirdreview .userreview .card9 .descrip {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 400px;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
}

.thirdreview .userreview .card9 .roundphoto {
    width: 42px;
    height: 42px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    background-image: url("https://media.istockphoto.com/id/990454674/photo/young-beautiful-asian-high-school-girl-or-college-student-wearing-eyeglasses-smiling-in.jpg?s=612x612&w=0&k=20&c=3VPR83IzsAlTCmc5qx_aFEC4AD8nHnxY9WpJDnv4F4g=");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 100%;
    max-width: 100%;
    display: block;
    margin-top: 7rem;
}

.thirdreview .userreview .card9 .fname {
    width: auto;
    height: auto;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: left;
    position: absolute;
    margin-top: 7.1rem;
    margin-left: 3.5rem;
}

.thirdreview .userreview .card9 .uname {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.8;
    color: #ffffff;
    line-height: 1.2;
    text-align: left;
    font-size: 0.8rem;
    margin-left: 3.4rem;
    margin-top: 2.55rem;
}

/* All Cards End Here */


.fourthsection {
    width: 100%;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    flex-direction: column;
}

.fourthsection .instantaccess {
    font-family: 'SwitzerSemibold';
    font-size: 4rem;
    margin-bottom: 1rem;
}

.fourthsection .accessparagraph {
    font-family: 'Satoshiregular';
    color: #c9c9c9;
    max-width: 30vw;
    font-size: 1.4rem;
    text-align: center;
}

.fourthsection .inputform {
    width: 37%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;

}

.fourthsection .inputform .emailbox {
    width: 22vw;
    height: 2.7rem;
    background-color: #333333;
    border-radius: 0.5vw;
    border: none;
    outline: none;
    appearance: none;
    caret-color: #ffffff;
    padding-left: 1rem;
    color: #767676;
    font-size: 1rem;
}

.fourthsection .inputform button {
    width: 10rem;
    background-color: white;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    outline: none;
    border: none;
    appearance: none;
    font-weight: 530;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 0.7rem;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
}

.fourthsection .inputform button:hover {
    background-color: #f2f2f2;
}

.footer {
    box-sizing: border-box;
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px 40px 20px;
    /* max-width: 1600px; */
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    border-radius: 0px 0px 0px 0px;
    border-color: rgba(255, 255, 255, 0.15);
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.footer p {
    width: auto;
    height: auto;
    white-space: pre;
    opacity: 0.5;
    font-weight: 500;
    font-style: normal;
    font-family: "Satoshi", "Satoshi Placeholder", sans-serif;
    color: #ffffff;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 1.2;
    text-align: center;
}

.footer .socialmedia {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.footer .socialmedia img {
    width: 24px;
    height: 24px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer;
}



.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: -35%;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 9999;
}

.overlay-content {
    margin-bottom: 3rem;
    width: 70%;
    height: 15%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: visible;
    overflow: auto;
    /* Updated line */
}


.overlay-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.overlay-content::-webkit-scrollbar {
    width: 9px;
    border-radius: 20px;
    background-color: #F5F5F5;
}

.overlay-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}


.overlay-content p {
    font-size: 1.2rem;
    padding: 1rem;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}


/* For Small Devices */
@media (min-width: 319px) and (max-width: 380px) {
    .navbar img {
        width: 7rem;
        height: 5.5rem;
        margin-top: 0.6vw;
        margin-left: 5vw;
    }

    .rightnav h4 {
        display: none;
    }

    .rightnav i {
        display: none;
    }

    .navbar button {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        padding: 0.75rem;
        border-radius: 0.7rem;
        margin-right: 1.8rem;
        margin-top: 0.5rem;
    }

    .navbar button:hover {
        background-color: white;
    }

    .navbar button:active {
        padding: 0.7rem;
    }

    .circleoutline {
        width: 356%;
        height: 100%;
        display: block;
        background: radial-gradient(33.7% 50% at 50% 0%, #ffffff 0%, rgba(255, 255, 255, 0) 68.44911317567568%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 52.99%;
    }

    .circlecss {
        width: 355%;
        height: 100%;
        display: block;
        box-shadow: 0px 0px 250px 0px rgba(230, 169, 251, 0.6);
        background: radial-gradient(64.4% 50% at 50% 50%, #000000 77.98599380630633%, rgb(160, 104, 252) 100%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 53%;
    }

    .blackbox {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 11rem;
        height: 1.7rem;
        background-color: #0D0D0D;
        border-radius: 0.6rem;
        border: 1px solid rgb(82, 77, 77);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0rem;
    }

    .blackbox p {
        color: #5F5F5F;
        font-size: 0.58rem;
    }

    .blackbox .readmore {
        cursor: pointer;
        margin-left: 0.4rem;
        color: #ffffff;
    }

    .mygrid h1 {
        font-weight: 600;
        font-size: 3rem;
        margin-left: 1vw;
        /* white-space: nowrap; */
        line-height: 3.5rem;
    }

    .welcomegreat {
        font-weight: 300;
        margin-top: 2rem;
        font-size: 1.3rem;
        width: 19rem;
        text-align: center;

    }

    .loginbutton {
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 75vh;
        left: 30.8%;
    }

    .trusted {
        font-family: "SatoshiMedium";
        font-weight: medium;
        font-size: 0.7rem;
        margin-top: 0rem;
        color: #797979;
    }

    .logogrid .mylogos {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 0.9fr 1.1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
            "img1 . img2"
            "img3 img4 img5"
            ". img6 .";
        position: relative;
    }

    .mylogos img:nth-child(1) {
        width: 9rem;
        height: 7rem;
        grid-area: img1;
        position: absolute;
        left: 2.5rem;
    }

    .mylogos img:nth-child(2) {
        width: 7rem;
        height: 2.6rem;
        grid-area: img2;
        position: absolute;
        right: 2.5rem
    }

    .mylogos img:nth-child(3) {
        width: 7rem;
        height: 3rem;
        grid-area: img3;
        position: absolute;
        bottom: 6rem;
        left: 1rem;
    }

    .mylogos img:nth-child(4) {
        width: 4.5rem;
        height: 4rem;
        grid-area: img4;
        position: absolute;
        bottom: 5.1rem;
        left: 2.9rem;
    }

    .mylogos img:nth-child(5) {
        width: 8rem;
        height: 8rem;
        grid-area: img5;
        position: relative;
        bottom: 3.5rem;
    }

    .mylogos img:nth-child(6) {
        width: 6rem;
        height: 4rem;
        grid-area: img6;
        position: absolute;
        bottom: 8rem;
        left: 2.5rem;
    }

    .cursorimg {
        display: none;
    }

    .messageimg {
        display: none;
    }

    .seconddescription {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        /* Using auto instead of 1fr for dynamic row height */
        gap: 0px;
        position: relative;
        bottom: 35rem;
        overflow-x: hidden;
        justify-content: center;
        align-items: center;
    }

    .seconddescription h1 {
        font-family: 'SwitzerSemibold';
        justify-self: center;
        font-size: 2rem;
        margin: 0;
        white-space: nowrap;
    }

    .seconddescription p {
        font-family: 'SatoshiRegular';
        justify-self: center;
        font-size: 1.2rem;
        width: 23rem;
        margin: 0;
        /* Reset margin to remove default spacing */
        margin-top: 1.5rem;
        color: #C9C9C9;
    }

    .threemains {
        margin-top: 5rem;
        justify-self: center;
        display: flex;
        flex-direction: column;
        gap: 4.5vw;
    }

    .seconddescription .boxx1 {
        box-sizing: border-box;
        width: 91vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx2 {
        box-sizing: border-box;
        width: 91vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx3 {
        box-sizing: border-box;
        width: 91vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .mysecondbackground {
        width: 100%;
        height: 200vh;
        position: absolute;
        top: 60%;
    }

    .secondbg {
        width: 100%;
        height: 100%;
        /* Changed height to auto for responsiveness */
        position: absolute;
    }

    .thirddescription {
        position: relative;
        bottom: 27rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200%;
    }

    .thirddescription h1 {
        font-family: 'SwitzerSemibold';
        font-size: 230.5%;
        margin: 0;
    }

    .thirddescription p {
        font-family: 'SatoshiRegular';
        font-size: 4vw;
        color: #c9c9c9;
        width: 80vw;
        text-align: center;
        margin-top: 3vw;
        margin-bottom: 2vw;
    }

    .thirddescription .appinterface {
        margin-top: 5vw;
        width: 80%;
        height: 60%;
    }

    .thirdreview {
        bottom: 24rem;
    }

    .thirdreview h1 {
        font-family: 'SwitzerSemibold';
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .fourthsection {
        width: 100%;
        height: min-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-top: 0rem;
        flex-direction: column;
    }

    .fourthsection .instantaccess {
        font-family: 'SwitzerSemibold';
        font-size: 2rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    .fourthsection .accessparagraph {
        font-family: 'Satoshiregular';
        color: #c9c9c9;
        max-width: 90vw;
        font-size: 1.1rem;
        text-align: center;
    }

    .fourthsection .inputform {
        width: 87%;
        height: 7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .fourthsection .inputform .emailbox {
        width: 82vw;
        height: 3.1rem;
        background-color: #333333;
        border-radius: 1vw;
        border: none;
        outline: none;
        appearance: none;
        caret-color: #ffffff;
        padding-left: 1rem;
        color: #767676;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .fourthsection .inputform button {
        width: 19rem;
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1rem;
        padding: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
    }

    .footer {
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 40px 20px;
        /* max-width: 1600px; */
        overflow: hidden;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 0px 0px 0px 0px;
        border-color: rgba(255, 255, 255, 0.15);
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
    }


    .footer .socialmedia {
        margin-top: 1rem;
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }


    .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 9999;
    }

    .overlay-content {
        margin-bottom: 3rem;
        width: 90%;
        height: 75%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: visible;
        overflow: auto;
        /* Updated line */
    }


    .overlay-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar {
        width: 9px;
        border-radius: 20px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }


    .overlay-content p {
        font-size: 1.2rem;
        padding: 1rem;
    }

}

/* For Medium Devices */
@media (min-width: 381px) and (max-width: 800px) {

    .navbar img {
        width: 7rem;
        height: 5.5rem;
        margin-top: 0.6vw;
        margin-left: 5vw;
    }

    .rightnav h4 {
        display: none;
    }

    .rightnav i {
        display: none;
    }

    .navbar button {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        padding: 0.75rem;
        border-radius: 0.7rem;
        margin-right: 1.8rem;
        margin-top: 0.5rem;
    }

    .navbar button:hover {
        background-color: white;
    }

    .navbar button:active {
        padding: 0.7rem;
    }

    .circleoutline {
        width: 356%;
        height: 100%;
        display: block;
        background: radial-gradient(33.7% 50% at 50% 0%, #ffffff 0%, rgba(255, 255, 255, 0) 68.44911317567568%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 52.89%;
    }

    .circlecss {
        width: 355%;
        height: 100%;
        display: block;
        box-shadow: 0px 0px 250px 0px rgba(230, 169, 251, 0.6);
        background: radial-gradient(64.4% 50% at 50% 50%, #000000 77.98599380630633%, rgb(160, 104, 252) 100%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 53%;
    }

    .blackbox {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 15rem;
        height: 2.2rem;
        background-color: #0D0D0D;
        border-radius: 0.6rem;
        border: 1px solid rgb(82, 77, 77);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0rem;
    }

    .mygrid h1 {
        font-weight: 600;
        font-size: 3.5rem;
        margin-left: 1vw;
        /* white-space: nowrap; */
        line-height: 4.2rem;
    }

    .welcomegreat {
        font-weight: 300;
        margin-top: 2rem;
        font-size: 1.5rem;
        width: 23rem;
        text-align: center;

    }

    .loginbutton {
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 75vh;
        left: 33.5%;
    }

    .trusted {
        font-family: "SatoshiMedium";
        font-weight: medium;
        font-size: 0.7rem;
        margin-top: 0rem;
        color: #797979;
    }

    .logogrid .mylogos {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 0.9fr 1.1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
            "img1 . img2"
            "img3 img4 img5"
            ". img6 .";
        position: relative;
    }

    .mylogos img:nth-child(1) {
        width: 9rem;
        height: 7rem;
        grid-area: img1;
        position: absolute;
        left: 2.5rem;
    }

    .mylogos img:nth-child(2) {
        width: 7rem;
        height: 2.6rem;
        grid-area: img2;
        position: absolute;
        right: 2.5rem
    }

    .mylogos img:nth-child(3) {
        width: 7rem;
        height: 3rem;
        grid-area: img3;
        position: absolute;
        bottom: 6rem;
        left: 1rem;
    }

    .mylogos img:nth-child(4) {
        width: 4.5rem;
        height: 4rem;
        grid-area: img4;
        position: absolute;
        bottom: 5.1rem;
        left: 2.9rem;
    }

    .mylogos img:nth-child(5) {
        width: 8rem;
        height: 8rem;
        grid-area: img5;
        position: relative;
        bottom: 3.5rem;
    }

    .mylogos img:nth-child(6) {
        width: 6rem;
        height: 4rem;
        grid-area: img6;
        position: absolute;
        bottom: 8rem;
        left: 2.5rem;
    }


    .cursorimg {
        display: none;
    }

    .messageimg {
        display: none;
    }

    .seconddescription {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        /* Using auto instead of 1fr for dynamic row height */
        gap: 0px;
        position: relative;
        bottom: 35rem;
        overflow-x: hidden;
        justify-content: center;
        align-items: center;
    }

    .seconddescription h1 {
        font-family: 'SwitzerSemibold';
        justify-self: center;
        font-size: 2rem;
        margin: 0;
        white-space: nowrap;
    }

    .seconddescription p {
        font-family: 'SatoshiRegular';
        justify-self: center;
        font-size: 1.2rem;
        width: 23rem;
        margin: 0;
        /* Reset margin to remove default spacing */
        margin-top: 1.5rem;
        color: #C9C9C9;
    }

    .threemains {
        margin-top: 5rem;
        justify-self: center;
        display: flex;
        flex-direction: column;
        gap: 4.5vw;
    }


    .seconddescription .boxx1 {
        box-sizing: border-box;
        width: 91vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }


    .seconddescription .boxx2 {
        box-sizing: border-box;
        width: 91vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }


    .seconddescription .boxx3 {
        box-sizing: border-box;
        width: 91vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .mysecondbackground {
        width: 100%;
        height: 150vh;
        position: absolute;
        top: 62%;
    }

    .secondbg {
        width: 100%;
        height: 100%;
        /* Changed height to auto for responsiveness */
        position: absolute;
    }

    .thirddescription {
        position: relative;
        bottom: 27rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200%;
    }

    .thirddescription h1 {
        font-family: 'SwitzerSemibold';
        font-size: 230.5%;
        margin: 0;
    }

    .thirddescription p {
        font-family: 'SatoshiRegular';
        font-size: 4vw;
        color: #c9c9c9;
        width: 80vw;
        text-align: center;
        margin-top: 3vw;
        margin-bottom: 2vw;
    }

    .thirddescription .appinterface {
        margin-top: 5vw;
        width: 80%;
        height: 60%;
    }

    .thirdreview {
        bottom: 24rem;
    }

    .thirdreview h1 {
        font-family: 'SwitzerSemibold';
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .fourthsection {
        width: 100%;
        height: min-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-top: 0rem;
        flex-direction: column;
    }

    .fourthsection .instantaccess {
        font-family: 'SwitzerSemibold';
        font-size: 2rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    .fourthsection .accessparagraph {
        font-family: 'Satoshiregular';
        color: #c9c9c9;
        max-width: 90vw;
        font-size: 1.1rem;
        text-align: center;
    }

    .fourthsection .inputform {
        width: 87%;
        height: 7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .fourthsection .inputform .emailbox {
        width: 82vw;
        height: 3.1rem;
        background-color: #333333;
        border-radius: 1vw;
        border: none;
        outline: none;
        appearance: none;
        caret-color: #ffffff;
        padding-left: 1rem;
        color: #767676;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .fourthsection .inputform button {
        width: 19rem;
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1rem;
        padding: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
    }

    .footer {
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 40px 20px;
        /* max-width: 1600px; */
        overflow: hidden;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 0px 0px 0px 0px;
        border-color: rgba(255, 255, 255, 0.15);
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
    }


    .footer .socialmedia {
        margin-top: 1rem;
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }


    .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 9999;
    }

    .overlay-content {
        margin-bottom: 3rem;
        width: 90%;
        height: 75%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: visible;
        overflow: auto;
        /* Updated line */
    }


    .overlay-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar {
        width: 9px;
        border-radius: 20px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }


    .overlay-content p {
        font-size: 1.2rem;
        padding: 1rem;
    }
}

/* For Tablet Devices */
@media (min-width: 767px) and (max-width: 1025px) {

    .blackbox {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 20rem;
        height: 3rem;
        background-color: #0D0D0D;
        border-radius: 0.6rem;
        border: 1px solid rgb(82, 77, 77);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 2rem;
    }

    .blackbox p {
        color: #5F5F5F;
        font-size: 1.2rem;
    }

    .blackbox .readmore {
        cursor: pointer;
        margin-left: 0.7rem;
        color: #ffffff;
    }

    .mygrid h1 {
        font-weight: 600;
        font-size: 6rem;
        margin-left: 1.5vw;
        line-height: 5rem;
    }

    .blackbox svg {
        margin-left: 0.1rem;
        cursor: pointer;
        color: #ffffff;
        width: 1.3rem;
        height: 1.3rem;
        margin-top: 0.3rem;
    }

    .circleoutline {
        width: 256%;
        height: 100%;
        display: block;
        background: radial-gradient(33.7% 50% at 50% 0%, #ffffff 0%, rgba(255, 255, 255, 0) 68.44911317567568%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 67.9%;
    }

    .circlecss {
        width: 255%;
        height: 100%;
        display: block;
        box-shadow: 0px 0px 250px 0px rgba(230, 169, 251, 0.6);
        background: radial-gradient(64.4% 50% at 50% 50%, #000000 77.98599380630633%, rgb(160, 104, 252) 100%);
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        top: 68%;
    }

    .welcomegreat {
        font-weight: 300;
        margin-top: 2rem;
        font-size: 1.4rem;
        width: 33rem;
        text-align: center;
    }

    .loginbutton {
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 94vh;
        left: 41.5%;
    }

    .cursorimg {
        width: 211px;
        height: 211px;
        display: block;
        -webkit-filter: drop-shadow(10px -19px 10px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(10px -19px 10px rgba(0, 0, 0, 0.5));
        overflow: visible;
        transform: rotate(9deg);
        z-index: 1;
        aspect-ratio: 1 / 1;
        background-image: url("./images/cursor.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 0px 0px 0px 0px;
        cursor: pointer;
        position: absolute;
        margin-right: 44.5rem;
        margin-top: 20vw;
    }

    .messageimg {
        width: 211px;
        height: 211px;
        display: block;
        -webkit-filter: drop-shadow(-10px 20px 10px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(-10px 20px 10px rgba(0, 0, 0, 0.5));
        overflow: visible;
        transform: rotate(-8deg);
        z-index: 1;
        aspect-ratio: 1 / 1;
        background-image: url("./images/messageimg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 0px 0px 0px 0px;
        position: absolute;
        margin-left: 47rem;
        margin-top: 10vw;
    }

    .trusted {
        font-family: "SatoshiMedium";
        font-weight: medium;
        font-size: 1.3rem;
        margin-top: 0rem;
        color: #797979;
    }

    .logogrid .mylogos {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        overflow: hidden;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 6rem;
        grid-template-areas:
            "img1 img2 img3"
            "img4 img5 img6";
    }

    .mylogos img:nth-child(1) {
        width: 9rem;
        height: 7rem;
        grid-area: img1;
    }

    .mylogos img:nth-child(2) {
        width: 7rem;
        height: 3rem;
        grid-area: img2;
    }

    .mylogos img:nth-child(3) {
        width: 7rem;
        height: 3rem;
        grid-area: img3;
    }

    .mylogos img:nth-child(4) {
        width: 6rem;
        height: 4.8rem;
        grid-area: img4;
    }

    .mylogos img:nth-child(5) {
        width: 9rem;
        height: 8rem;
        grid-area: img5;
    }

    .mylogos img:nth-child(6) {
        width: 6rem;
        height: 4rem;
        grid-area: img6;
    }

    .mygrid {
        overflow: hidden;
    }

    .cursorimg {
        overflow: hidden;
    }

    .messageimg {
        overflow: hidden;
    }

    .seconddescription h1 {
        font-family: 'SwitzerSemibold';
        justify-self: center;
        font-size: 5rem;
        margin: 0;
        white-space: nowrap;
    }

    .seconddescription p {
        font-family: 'SatoshiRegular';
        justify-self: center;
        font-size: 2.4rem;
        width: 50rem;
        margin: 0;
        /* Reset margin to remove default spacing */
        margin-top: 1.5rem;
        color: #C9C9C9;
    }

    .threemains {
        margin-top: 5rem;
        justify-self: center;
        display: flex;
        flex-direction: column;
        gap: 3.5vw;
    }

    .seconddescription .boxx1 {
        box-sizing: border-box;
        width: 71vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx1 .icon1 {
        width: 10vw;
        height: 5vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0px 0px 0px 0px;
        align-content: center;
        flex-wrap: nowrap;
        gap: 10;
        aspect-ratio: 1 / 1;
        position: absolute;
        border-radius: 10px;
        background-color: white;
    }


    .seconddescription .boxx1 h6 {
        font-family: 'SwitzerSemibold';
        font-size: 2.4rem;
        margin-top: 7rem;
    }

    .seconddescription .boxx1 p {
        margin: 1rem;
        font-weight: 200;
        font-family: 'SatoshiRegular';
        font-size: 2rem;
        width: 40rem;
        color: #C9C9C9;
    }

    .seconddescription .boxx2 {
        box-sizing: border-box;
        width: 71vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx2 .icon2 {
        width: 10vw;
        height: 5vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0px 0px 0px 0px;
        align-content: center;
        flex-wrap: nowrap;
        gap: 10;
        aspect-ratio: 1 / 1;
        position: absolute;
        border-radius: 10px;
        background-color: white;
    }


    .seconddescription .boxx2 h6 {
        font-family: 'SwitzerSemibold';
        font-size: 2.4rem;
        margin-top: 7rem;
    }

    .seconddescription .boxx2 p {
        margin: 1rem;
        font-weight: 200;
        font-family: 'SatoshiRegular';
        font-size: 2rem;
        max-width: 40rem;
        color: #C9C9C9;
    }

    .seconddescription .boxx3 {
        box-sizing: border-box;
        width: 71vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx3 .icon3 {
        width: 10vw;
        height: 5vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0px 0px 0px 0px;
        align-content: center;
        flex-wrap: nowrap;
        gap: 10;
        aspect-ratio: 1 / 1;
        position: absolute;
        border-radius: 10px;
        background-color: white;
    }


    .seconddescription .boxx3 h6 {
        font-family: 'SwitzerSemibold';
        font-size: 2.4rem;
        margin-top: 7rem;
    }

    .seconddescription .boxx3 p {
        margin: 1rem;
        font-weight: 200;
        font-family: 'SatoshiRegular';
        font-size: 2rem;
        max-width: 40rem;
        color: #C9C9C9;
    }

    .mysecondbackground {
        width: 100%;
        height: 150vh;
        position: absolute;
        top: 65%;
    }

    .secondbg {
        width: 100%;
        height: 100%;
        /* Changed height to auto for responsiveness */
        position: absolute;
    }


    .thirddescription {
        position: relative;
        bottom: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 300%;
    }

    .thirddescription h1 {
        font-family: 'SwitzerSemibold';
        font-size: 430.5%;
        margin: 0;
    }

    .thirddescription p {
        font-family: 'SatoshiRegular';
        font-size: 4vw;
        color: #c9c9c9;
        width: 80vw;
        text-align: center;
        margin-top: 3vw;
        margin-bottom: 2vw;
    }

    .thirddescription .appinterface {
        margin-top: 5vw;
        width: 80%;
        height: 60%;
    }

    .thirdreview {
        bottom: 3rem;
    }

    .thirdreview h1 {
        font-family: 'SwitzerSemibold';
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }


    .fourthsection {
        width: 100%;
        height: min-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        flex-direction: column;
    }

    .fourthsection .instantaccess {
        font-family: 'SwitzerSemibold';
        font-size: 5rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    .fourthsection .accessparagraph {
        font-family: 'Satoshiregular';
        color: #c9c9c9;
        max-width: 80vw;
        font-size: 2.5rem;
        text-align: center;
    }

    .fourthsection .inputform {
        width: 87%;
        height: 7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-around;
    }


    .fourthsection .inputform .emailbox {
        width: 42vw;
        height: 1rem;
        background-color: #333333;
        border-radius: 0.5vw;
        border: none;
        outline: none;
        appearance: none;
        caret-color: #ffffff;
        padding-left: 1rem;
        color: #767676;
        padding: 2rem;
        font-size: 1.6rem;
        margin-top: 1rem;
    }

    .fourthsection .inputform button {
        width: 15rem;
        height: 4.5rem;
        background-color: white;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        outline: none;
        border: none;
        appearance: none;
        font-weight: 530;
        font-size: 1rem;
        border-radius: 0.7rem;
        overflow: hidden;
        cursor: pointer;
        text-align: center;
        margin-top: 0.5rem;

    }

}


/* For Smaller Ipad Tablets */
@media (min-width: 500px) and (max-width: 700px) {
    .mygrid h1 {
        font-weight: 600;
        font-size: 5rem;
        margin-left: 0;
        /* white-space: nowrap; */
        /* font-family: "Helvetica"; */
        line-height: 5rem;
    }

    .welcomegreat {
        font-weight: 300;
        margin-top: 2rem;
        font-size: 1.5rem;
        width: 33rem;
        text-align: center;
    }

    .seconddescription h1 {
        font-family: 'SwitzerSemibold';
        justify-self: center;
        font-size: 3rem;
        margin: 0;
        white-space: nowrap;
    }

    .seconddescription .empowerp {
        font-family: 'SatoshiRegular';
        font-size: 0.6rem;
        color: #C9C9C9;
        max-width: 34rem;
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .mysecondbackground {
        width: 100%;
        height: 200vh;
        position: absolute;
        top: 360%;
    }


}

/* Ipad Mini */
@media (min-width: 600px) and (max-width: 860px) {

    .seconddescription .empowerp {
        font-family: 'SatoshiRegular';
        font-size: 2rem;
        color: #C9C9C9;
        width: 45rem;
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .seconddescription .boxx1 {
        box-sizing: border-box;
        width: 90vw;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx2 {
        box-sizing: border-box;
        width: 90vw;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }

    .seconddescription .boxx3 {
        box-sizing: border-box;
        width: 90vw;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 40px 40px 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: center;
        flex-wrap: nowrap;
        gap: 30;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0 auto;
        /* Center the box horizontally */
    }
}

/* Iphone 14 Pro Max */
@media (min-width: 394px) and (max-width: 415px) {

    .mygrid h1 {
        font-weight: 600;
        font-size: 3.1rem;
        margin-left: 1.5vw;
        /* white-space: nowrap; */
        /* font-family: "Helvetica"; */
        line-height: 4rem;
    }

    .mysecondbackground {
        width: 100%;
        height: 150vh;
        position: absolute;
        top: 344%;
    }

    .thirdreview .userreview .card1 {
        box-sizing: border-box;
        width: 340px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
    }

    .thirdreview .userreview .card2 {
        box-sizing: border-box;
        width: 340px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
        top: 25rem;
    }

    .thirdreview .userreview .card3 {
        box-sizing: border-box;
        width: 340px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
        top: 40rem;
    }

    .thirdreview .userreview .card4 {
        display: none;
    }

    .thirdreview .userreview .card5 {
        display: none;
    }

    .thirdreview .userreview .card6 {
        display: none;
    }

    .thirdreview .userreview .card7 {
        display: none;
    }

    .thirdreview .userreview .card8 {
        display: none;
    }

    .thirdreview .userreview .card9 {
        display: none;
    }

    .footer {
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 40px 20px;
        /* max-width: 1600px; */
        overflow: hidden;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 0px 0px 0px 0px;
        border-color: rgba(255, 255, 255, 0.15);
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
    }


    .footer .socialmedia {
        margin-top: 1rem;
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }


    .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 9999;
    }

    .overlay-content {
        margin-bottom: 3rem;
        width: 90%;
        height: 75%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: visible;
        overflow: auto;
        /* Updated line */
    }


    .overlay-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar {
        width: 9px;
        border-radius: 20px;
        background-color: #F5F5F5;
    }

    .overlay-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }


    .overlay-content p {
        font-size: 1.2rem;
        padding: 1rem;
    }

}

@media (min-width: 320px) and (max-width: 380px) {
    .mygrid h1 {
        font-weight: 600;
        font-size: 2.8rem;
        margin-left: 1.5vw;
        /* white-space: nowrap; */
        /* font-family: "Helvetica"; */
        line-height: 4rem;
    }

    .welcomegreat {
        font-weight: 300;
        margin-top: 2rem;
        font-size: 1rem;
        width: 19rem;
        text-align: center;
    }

    .seconddescription .empowerp {
        font-family: 'SatoshiRegular';
        font-size: 1.2rem;
        color: #C9C9C9;
        width: 19rem;
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .mysecondbackground {
        width: 100%;
        height: 150vh;
        position: absolute;
        top: 384%;
    }


    .thirdreview .userreview .card1 {
        box-sizing: border-box;
        width: 310px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
    }

    .thirdreview .userreview .card2 {
        box-sizing: border-box;
        width: 310px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
        top: 25rem;
    }

    .thirdreview .userreview .card3 {
        box-sizing: border-box;
        width: 310px;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
        background-color: #0d0d0d;
        overflow: hidden;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 20;
        position: absolute;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 400px;
        padding: 30px;
        margin: 0;
        top: 40rem;
    }

    .thirdreview .userreview .card4 {
        display: none;
    }

    .thirdreview .userreview .card5 {
        display: none;
    }

    .thirdreview .userreview .card6 {
        display: none;
    }

    .thirdreview .userreview .card7 {
        display: none;
    }

    .thirdreview .userreview .card8 {
        display: none;
    }

    .thirdreview .userreview .card9 {
        display: none;
    }

    .footer {
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 40px 20px;
        /* max-width: 1600px; */
        overflow: hidden;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 0px 0px 0px 0px;
        border-color: rgba(255, 255, 255, 0.15);
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
    }


    .footer .socialmedia {
        margin-top: 1rem;
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }
}